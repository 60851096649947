<template>
    <div class="content">
        <b-loading :active.sync="isLoadingUsergroup"></b-loading>
        <form @submit.prevent="updateUsergroup">
            <b-field grouped group-multiline>
                <b-field
                    :label="$t('general.name')"
                    size="is-small"
                    label-position="inside"
                    expanded
                >
                    <b-input
                        v-model="usergroup.name"
                        size="is-small"
                        placeholder=""
                    />
                </b-field>
                <b-field :label="$t('usergroup.web')" label-position="inside">
                    <b-input
                        v-model="usergroup.web"
                        size="is-small"
                        placeholder=""
                        expanded=""
                    />
                </b-field>
                <b-field :label="$t('usergroup.print')" label-position="inside">
                    <b-input
                        v-model="usergroup.print"
                        size="is-small"
                        placeholder=""
                        expanded=""
                    />
                </b-field>
                <div class="block is-inline-flex">
                    <b-checkbox v-model="usergroup.inactive" native-value="1">
                        {{ $t('usergroup.inactive') }}
                    </b-checkbox>
                </div>
                <b-field expanded>
                    <div class="buttons is-pulled-right">
                        <b-button
                            type="is-success"
                            class="is-light "
                            size="is-small"
                            outlined
                            rounded
                            icon-left="check"
                            @click="updateUsergroup"
                        >
                            {{ $t('usergroup.buttons.update') }}
                        </b-button>
                    </div>
                </b-field>
            </b-field>
        </form>
        <hr />
    </div>
</template>

<script>
export default {
    name: 'EditUsergroup',
    props: { usergroup: Object },
    computed: {
        isLoadingUsergroup() {
            return this.$store.getters['usergroup/isLoading']
        },
        hasError() {
            return this.$store.getters['usergroup/hasError']
        }
    },
    methods: {
        async updateUsergroup() {
            if (
                (await this.$store.dispatch(
                    'usergroup/update',
                    this.usergroup
                )) &&
                !this.hasError
            ) {
                this.$buefy.snackbar.open(this.$t('usergroup.update.success'))
            }
        }
    }
}
</script>
