<template>
    <section class="section">
        <div class="columns">
            <div class="column is-four-fifths">
                <div class="content">
                    <h1>
                        {{
                            $t('usergroup.edit.header', {
                                company: editUsergroup.name
                            })
                        }}
                    </h1>
                </div>
                <form v-if="usergroup" @submit.prevent="updateUsergroup">
                    <div class="columns is-multiline">
                        <div class="column is-12-desktop">
                            <b-field
                                :label="$t('usergroup.edit.name.label')"
                                label-position="inside"
                                expanded
                            >
                                <b-input
                                    v-model="editUsergroup.name"
                                    :placeholder="
                                        $t('usergroup.edit.name.placeholder')
                                    "
                                    icon="account-question-outline"
                                    required
                                />
                            </b-field>
                        </div>
                        <div class="column is-6-desktop">
                            <b-field
                                :label="$t('usergroup.edit.companyName.label')"
                                label-position="inside"
                                expanded
                            >
                                <b-input
                                    v-model="
                                        editUsergroup
                                            .usergroupInvoiceInformation.name
                                    "
                                    :placeholder="
                                        $t(
                                            'usergroup.edit.companyName.placeholder'
                                        )
                                    "
                                    icon="account-question-outline"
                                />
                            </b-field>
                        </div>
                        <div class="column is-3-desktop">
                            <b-field
                                :label="$t('usergroup.edit.reference.label')"
                                label-position="inside"
                                expanded
                            >
                                <b-input
                                    v-model="
                                        editUsergroup
                                            .usergroupInvoiceInformation
                                            .reference
                                    "
                                    :placeholder="
                                        $t(
                                            'usergroup.edit.reference.placeholder'
                                        )
                                    "
                                    icon="account-question-outline"
                                />
                            </b-field>
                        </div>
                        <div class="column is-3-desktop">
                            <b-field
                                :label="$t('usergroup.edit.orgNo.label')"
                                label-position="inside"
                                expanded
                            >
                                <b-input
                                    v-model="
                                        editUsergroup
                                            .usergroupInvoiceInformation.orgNo
                                    "
                                    :placeholder="
                                        $t('usergroup.edit.orgNo.placeholder')
                                    "
                                    icon="account-question-outline"
                                />
                            </b-field>
                        </div>
                        <div class="column is-6-desktop">
                            <b-field
                                :label="$t('usergroup.edit.email.label')"
                                label-position="inside"
                                expanded
                            >
                                <b-input
                                    v-model="
                                        editUsergroup
                                            .usergroupInvoiceInformation.email
                                    "
                                    :placeholder="
                                        $t('usergroup.edit.email.placeholder')
                                    "
                                    icon="account-question-outline"
                                />
                            </b-field>
                        </div>
                        <div class="column is-6-desktop">
                            <b-field
                                :label="$t('usergroup.edit.dueDays.label')"
                                label-position="inside"
                                expanded
                            >
                                <b-input
                                    v-model.number="
                                        editUsergroup
                                            .usergroupInvoiceInformation.dueDays
                                    "
                                    :placeholder="
                                        $t('usergroup.edit.dueDays.placeholder')
                                    "
                                    icon="account-question-outline"
                                />
                            </b-field>
                        </div>
                        <div class="column is-12-desktop">
                            <b-field
                                :label="$t('usergroup.edit.address.label')"
                                label-position="inside"
                                expanded
                            >
                                <div class="control has-icons-left is-clearfix">
                                    <GoogleMapsAutocomplete
                                        v-model="searchedAddress"
                                        :value="searchedAddress"
                                        :countries="['se']"
                                        :types="['geocode']"
                                        :placeholder="
                                            $t(
                                                'usergroup.edit.address.placeholder'
                                            )
                                        "
                                        :required="true"
                                        @clean-result="
                                            cleanAddressResult($event)
                                        "
                                        @place-changed="
                                            googleMapsPlaceChanged(
                                                $event,
                                                'address'
                                            )
                                        "
                                    />
                                    <span class="icon is-left">
                                        <i
                                            class="mdi mdi-map-marker-outline mdi-24px"
                                        />
                                    </span>
                                </div>
                            </b-field>
                        </div>
                    </div>
                    <div class="buttons is-pulled-right">
                        <b-button
                            native-type="submit"
                            :value="$t('usergroup.buttons.update')"
                        >
                            {{ $t('usergroup.buttons.update') }}
                        </b-button>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
import GoogleMapsAutocomplete from '../../components/GoogleMapsAutocomplete'

export default {
    name: 'EditUsergroup',
    components: { GoogleMapsAutocomplete },
    props: { ug: Object },
    data() {
        return {
            uuid: '',
            address: { route: '' },
            searchedAddress: '',
            editUsergroup: {
                name: '',
                usergroupInvoiceInformation: {
                    name: '',
                    orgNo: '',
                    reference: '',
                    locationRoute: '',
                    locationStreetNumber: '',
                    locationPostalCode: '',
                    locationPostalTown: '',
                    locationCountry: '',
                    locationLat: '',
                    locationLng: '',
                    dueDays: 30,
                    email: ''
                }
            }
        }
    },
    computed: {
        isLoading() {
            return this.$store.getters['usergroup/isLoading']
        },
        hasError() {
            return this.$store.getters['usergroup/hasError']
        },
        error() {
            return this.$store.getters['usergroup/error']
        },
        usergroup() {
            return this.$store.getters['usergroup/usergroup']
        }
    },
    methods: {
        async updateUsergroup() {
            console.log(this.editUsergroup)
            await this.$store.dispatch('usergroup/update', this.editUsergroup)
        },
        googleMapsPlaceChanged($event, type) {
            this.address.route = $event.address.route
            this.editUsergroup.usergroupInvoiceInformation.locationRoute =
                $event.address.route
            this.editUsergroup.usergroupInvoiceInformation.locationStreetNumber =
                $event.address.street_number
            this.editUsergroup.usergroupInvoiceInformation.locationPostalTown =
                $event.address.postal_town
            this.editUsergroup.usergroupInvoiceInformation.locationPostalCode =
                $event.address.postal_code
            this.editUsergroup.usergroupInvoiceInformation.locationCountry =
                $event.address.country
            this.editUsergroup.usergroupInvoiceInformation.locationLat = String(
                $event.lat
            )
            this.editUsergroup.usergroupInvoiceInformation.locationLong = String(
                $event.lng
            )
        },
        cleanAddressResult(event) {
            this.searchedAddress = event
            this.address.route = ''
            this.editUsergroup.usergroupInvoiceInformation.locationRoute = null
            this.editUsergroup.usergroupInvoiceInformation.locationStreetNumber = null
            this.editUsergroup.usergroupInvoiceInformation.locationPostalTown = null
            this.editUsergroup.usergroupInvoiceInformation.locationPostalCode = null
            this.editUsergroup.usergroupInvoiceInformation.locationCountry = null
            this.editUsergroup.usergroupInvoiceInformation.locationLat = null
            this.editUsergroup.usergroupInvoiceInformation.locationLong = null
        }
    },
    async created() {
        console.log(this.ug)
        if (this.ug) {
            this.editUsergroup = {
                '@id': this.ug['@id'],
                name: this.ug.name,
                inactive: this.ug.inactive,
                usergroupInvoiceInformation: {
                    name: ''
                }
            }
        } else {
            console.log(this.$route.params.uuid)
            await this.$store.dispatch(
                'usergroup/view',
                this.$route.params.uuid
            )
            this.editUsergroup = {
                '@id': this.usergroup['@id'],
                name: this.usergroup.name,
                inactive: this.usergroup.inactive,
                usergroupInvoiceInformation: {
                    name: ''
                }
            }
        }
        this.uuid = this.$route.params.uuid
        // this.$store.dispatch('usergroup/view', this.props.ug['@id'])
    }
}
</script>

<style scoped>
.is-four-fifths {
    margin: 0 auto;
}
</style>
