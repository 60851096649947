var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"section"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-four-fifths"},[_c('div',{staticClass:"content"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('usergroup.edit.header', { company: _vm.editUsergroup.name }))+" ")])]),(_vm.usergroup)?_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.updateUsergroup($event)}}},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12-desktop"},[_c('b-field',{attrs:{"label":_vm.$t('usergroup.edit.name.label'),"label-position":"inside","expanded":""}},[_c('b-input',{attrs:{"placeholder":_vm.$t('usergroup.edit.name.placeholder'),"icon":"account-question-outline","required":""},model:{value:(_vm.editUsergroup.name),callback:function ($$v) {_vm.$set(_vm.editUsergroup, "name", $$v)},expression:"editUsergroup.name"}})],1)],1),_c('div',{staticClass:"column is-6-desktop"},[_c('b-field',{attrs:{"label":_vm.$t('usergroup.edit.companyName.label'),"label-position":"inside","expanded":""}},[_c('b-input',{attrs:{"placeholder":_vm.$t(
                                        'usergroup.edit.companyName.placeholder'
                                    ),"icon":"account-question-outline"},model:{value:(
                                    _vm.editUsergroup
                                        .usergroupInvoiceInformation.name
                                ),callback:function ($$v) {_vm.$set(_vm.editUsergroup
                                        .usergroupInvoiceInformation, "name", $$v)},expression:"\n                                    editUsergroup\n                                        .usergroupInvoiceInformation.name\n                                "}})],1)],1),_c('div',{staticClass:"column is-3-desktop"},[_c('b-field',{attrs:{"label":_vm.$t('usergroup.edit.reference.label'),"label-position":"inside","expanded":""}},[_c('b-input',{attrs:{"placeholder":_vm.$t(
                                        'usergroup.edit.reference.placeholder'
                                    ),"icon":"account-question-outline"},model:{value:(
                                    _vm.editUsergroup
                                        .usergroupInvoiceInformation
                                        .reference
                                ),callback:function ($$v) {_vm.$set(_vm.editUsergroup
                                        .usergroupInvoiceInformation
                                        , "reference", $$v)},expression:"\n                                    editUsergroup\n                                        .usergroupInvoiceInformation\n                                        .reference\n                                "}})],1)],1),_c('div',{staticClass:"column is-3-desktop"},[_c('b-field',{attrs:{"label":_vm.$t('usergroup.edit.orgNo.label'),"label-position":"inside","expanded":""}},[_c('b-input',{attrs:{"placeholder":_vm.$t('usergroup.edit.orgNo.placeholder'),"icon":"account-question-outline"},model:{value:(
                                    _vm.editUsergroup
                                        .usergroupInvoiceInformation.orgNo
                                ),callback:function ($$v) {_vm.$set(_vm.editUsergroup
                                        .usergroupInvoiceInformation, "orgNo", $$v)},expression:"\n                                    editUsergroup\n                                        .usergroupInvoiceInformation.orgNo\n                                "}})],1)],1),_c('div',{staticClass:"column is-6-desktop"},[_c('b-field',{attrs:{"label":_vm.$t('usergroup.edit.email.label'),"label-position":"inside","expanded":""}},[_c('b-input',{attrs:{"placeholder":_vm.$t('usergroup.edit.email.placeholder'),"icon":"account-question-outline"},model:{value:(
                                    _vm.editUsergroup
                                        .usergroupInvoiceInformation.email
                                ),callback:function ($$v) {_vm.$set(_vm.editUsergroup
                                        .usergroupInvoiceInformation, "email", $$v)},expression:"\n                                    editUsergroup\n                                        .usergroupInvoiceInformation.email\n                                "}})],1)],1),_c('div',{staticClass:"column is-6-desktop"},[_c('b-field',{attrs:{"label":_vm.$t('usergroup.edit.dueDays.label'),"label-position":"inside","expanded":""}},[_c('b-input',{attrs:{"placeholder":_vm.$t('usergroup.edit.dueDays.placeholder'),"icon":"account-question-outline"},model:{value:(
                                    _vm.editUsergroup
                                        .usergroupInvoiceInformation.dueDays
                                ),callback:function ($$v) {_vm.$set(_vm.editUsergroup
                                        .usergroupInvoiceInformation, "dueDays", _vm._n($$v))},expression:"\n                                    editUsergroup\n                                        .usergroupInvoiceInformation.dueDays\n                                "}})],1)],1),_c('div',{staticClass:"column is-12-desktop"},[_c('b-field',{attrs:{"label":_vm.$t('usergroup.edit.address.label'),"label-position":"inside","expanded":""}},[_c('div',{staticClass:"control has-icons-left is-clearfix"},[_c('GoogleMapsAutocomplete',{attrs:{"value":_vm.searchedAddress,"countries":['se'],"types":['geocode'],"placeholder":_vm.$t(
                                            'usergroup.edit.address.placeholder'
                                        ),"required":true},on:{"clean-result":function($event){return _vm.cleanAddressResult($event)},"place-changed":function($event){return _vm.googleMapsPlaceChanged(
                                            $event,
                                            'address'
                                        )}},model:{value:(_vm.searchedAddress),callback:function ($$v) {_vm.searchedAddress=$$v},expression:"searchedAddress"}}),_c('span',{staticClass:"icon is-left"},[_c('i',{staticClass:"mdi mdi-map-marker-outline mdi-24px"})])],1)])],1)]),_c('div',{staticClass:"buttons is-pulled-right"},[_c('b-button',{attrs:{"native-type":"submit","value":_vm.$t('usergroup.buttons.update')}},[_vm._v(" "+_vm._s(_vm.$t('usergroup.buttons.update'))+" ")])],1)]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }