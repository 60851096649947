<template>
    <div v-if="newCustomer">
        <b-field :label="$t('users.office')">
            <b-autocomplete
                ref="autocomplete"
                v-model="officeName"
                :data="filteredOfficeArray"
                :placeholder="$t('users.chooseOffice')"
                field="name"
                :open-on-focus="true"
                @select="
                    option =>
                        (customer.officeIri = option ? option['@id'] : null)
                "
            >
                <template slot="footer">
                    <a @click="showAddNewOffice">
                        <span>{{ $t('users.addNewOffice') }}</span>
                    </a>
                </template>
                <template slot="empty">
                    {{
                        $t('users.officeName', {
                            officeName: officeName
                        })
                    }}
                </template>
            </b-autocomplete>
        </b-field>

        <b-field grouped group-multiline>
            <b-field :label="$t('users.firstName')" expanded>
                <b-input
                    v-model="customer.firstName"
                    @input="proposedUsername()"
                />
            </b-field>

            <b-field :label="$t('users.lastName')" expanded>
                <b-input
                    v-model="customer.lastName"
                    @input="proposedUsername()"
                />
            </b-field>

            <b-field :label="$t('general.phone')" expanded>
                <b-input v-model="customer.phone" type="tel" />
            </b-field>
        </b-field>

        <b-field grouped group-multiline>
            <b-field :label="$t('general.email')" expanded>
                <b-input v-model="customer.email" type="email" />
            </b-field>

            <b-field :label="$t('users.username')" expanded>
                <b-input v-model="customer.username" />
            </b-field>
        </b-field>

        <b-field
            :label="$t('users.userRole')"
            :message="$t('users.roleExplanation')"
        >
            <div class="block">
                <b-radio
                    v-model="customer.type"
                    name="type"
                    native-value="broker"
                    selected
                >
                    {{ $t('users.broker') }}
                </b-radio>

                <b-radio
                    v-model="customer.type"
                    name="type"
                    native-value="customerAdmin"
                >
                    {{ $t('users.groupAdmin') }}
                </b-radio>
            </div>
        </b-field>
        <div class="buttons is-pulled-right">
            <b-button
                icon-right="cancel"
                type="is-danger"
                class="is-light"
                @click="newCustomer = false"
            />
            <b-button
                :disabled="
                    !customer.officeIri ||
                        !customer.email ||
                        !customer.username ||
                        !customer.firstName ||
                        !customer.lastName
                "
                type="is-success"
                class="is-light"
                @click="addNewUser"
            >
                {{ $t('general.add') }}
            </b-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NewCustomer',
    data() {
        return {
            officeName: '',
            customer: {
                officeIri: null,
                type: 'broker',
                email: null,
                username: '',
                firstName: '',
                lastName: '',
                phone: null
            }
        }
    },
    computed: {
        hasUsergroups() {
            return this.$store.getters['usergroup/hasUsergroups']
        },
        customerGroups() {
            return this.$store.getters['usergroup/usergroups']
        },
        filteredOfficeArray() {
            return this.customerGroups.filter(option => {
                return (
                    option.name
                        .toString()
                        .toLowerCase()
                        .indexOf(this.officeName.toLowerCase()) >= 0
                )
            })
        }
    },
    methods: {
        proposedUsername() {
            let fullName =
                this.customer.username !== '.'
                    ? this.customer.firstName + '.' + this.customer.lastName
                    : ''

            this.customer.username = fullName
                .toLowerCase()
                .replace(/[åÅäÄ]/g, 'a')
                .replace(/[öÖ]/g, 'o')
                .replace(/[^a-zA-Z0-9.]+/g, '-')
        },
        showAddNewOffice() {
            this.$buefy.dialog.prompt({
                message: 'Name of office',
                inputAttrs: {
                    placeholder: 'e.g. Fancy Office Name',
                    maxlength: 100,
                    value: this.officeName
                },
                confirmText: 'Add',
                onConfirm: async value => {
                    let newUsergroup = await this.$store.dispatch(
                        'usergroup/create',
                        { name: value, logo: null }
                    )
                    this.$refs.autocomplete.setSelected(newUsergroup)
                }
            })
        },
        addNewUser() {
            // console.log(this.customer)
            this.$store.dispatch('customer/create', this.customer)
        }
    }
}
</script>
