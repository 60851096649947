<template>
    <section class="section">
        <div class="columns">
            <div class="column is-four-fifths">
                <nav class="level">
                    <div class="level-left">
                        <div class="level-item">
                            <h1 class="title">
                                {{ $t('users.userManagement') }}
                            </h1>
                        </div>
                    </div>
                    <div class="level-right">
                        <div class="level-item">
                            <b-field>
                                <b-input
                                    v-model="search"
                                    :placeholder="$t('general.search')"
                                    type="search"
                                    icon="magnify"
                                    icon-clickable
                                    @icon-click="searchUsersAndGroups"
                                    @keyup.enter="searchUsersAndGroups"
                                >
                                </b-input>
                            </b-field>
                        </div>
                        <div class="level-item">
                            <button
                                v-if="!newCustomer"
                                class="button is-primary is-pulled-right"
                                @click="newCustomer = !newCustomer"
                            >
                                {{ $t('general.add') }}
                            </button>
                        </div>
                    </div>
                </nav>
                <transition name="fade">
                    <div v-if="newCustomer">
                        <b-field :label="$t('users.office')">
                            <b-autocomplete
                                ref="autocomplete"
                                v-model="officeName"
                                :data="filteredOfficeArray"
                                :placeholder="$t('users.chooseOffice')"
                                field="name"
                                :open-on-focus="true"
                                @select="
                                    option =>
                                        (customer.officeIri = option
                                            ? option['@id']
                                            : null)
                                "
                            >
                                <template slot="header">
                                    <a @click="showAddNewOffice">
                                        <span>{{
                                            $t('users.addNewOffice')
                                        }}</span>
                                    </a>
                                </template>
                                <template slot="empty">
                                    {{
                                        $t('users.officeName', {
                                            officeName: officeName
                                        })
                                    }}
                                </template>
                            </b-autocomplete>
                        </b-field>

                        <b-field grouped group-multiline>
                            <b-field :label="$t('users.firstName')" expanded>
                                <b-input
                                    v-model="customer.firstName"
                                    @input="proposedUsername()"
                                />
                            </b-field>

                            <b-field :label="$t('users.lastName')" expanded>
                                <b-input
                                    v-model="customer.lastName"
                                    @input="proposedUsername()"
                                />
                            </b-field>

                            <b-field :label="$t('general.phone')" expanded>
                                <b-input v-model="customer.phone" type="tel" />
                            </b-field>
                        </b-field>

                        <b-field grouped group-multiline>
                            <b-field :label="$t('general.email')" expanded>
                                <b-input
                                    v-model="customer.email"
                                    type="email"
                                />
                            </b-field>

                            <b-field :label="$t('users.username')" expanded>
                                <b-input v-model="customer.username" />
                            </b-field>
                        </b-field>

                        <b-field
                            :label="$t('users.userRole')"
                            :message="$t('users.roleExplanation')"
                        >
                            <div class="block">
                                <b-radio
                                    v-model="customer.type"
                                    name="type"
                                    native-value="broker"
                                    selected
                                >
                                    {{ $t('users.broker') }}
                                </b-radio>

                                <b-radio
                                    v-model="customer.type"
                                    name="type"
                                    native-value="customerAdmin"
                                >
                                    {{ $t('users.groupAdmin') }}
                                </b-radio>
                            </div>
                        </b-field>
                        <div class="buttons is-pulled-right">
                            <b-button
                                icon-right="cancel"
                                type="is-danger"
                                class="is-light"
                                @click="newCustomer = false"
                            />
                            <b-button
                                :disabled="
                                    !customer.officeIri ||
                                        !customer.email ||
                                        !customer.username ||
                                        !customer.firstName ||
                                        !customer.lastName
                                "
                                type="is-success"
                                class="is-light"
                                @click="addNewUser"
                            >
                                {{ $t('general.add') }}
                            </b-button>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <div class="columns">
            <div class="column is-four-fifths">
                <b-table
                    ref="table"
                    :data="customerGroups"
                    paginated
                    per-page="15"
                    :opened-detailed="defaultOpenedDetails"
                    detailed
                    :loading="isLoadingUsergroup"
                    detail-key="@id"
                    sort-icon="chevron-up"
                    :show-detail-icon="showDetailIcon"
                    aria-next-label="Next page"
                    aria-previous-label="Previous page"
                    aria-page-label="Page"
                    aria-current-label="Current page"
                    :mobile-cards="false"
                >
                    <b-table-column
                        v-slot="props"
                        field="name"
                        :label="$t('general.office')"
                        sortable
                    >
                        <template v-if="showDetailIcon">
                            <a @click="toggle(props.row)">
                                {{ props.row.name }}
                            </a>
                            <span class="is-size-7">
                                ({{ props.row.members.length }}
                                {{ $t('users.amount') }})
                            </span>
                        </template>
                    </b-table-column>

                    <b-table-column v-slot="props" numeric width="150">
                        <b-tooltip
                            v-if="props.row.members.length === 0"
                            :label="$t('users.deleteOffice')"
                            type="is-danger"
                            class="mr-3 is-hidden-mobile"
                        >
                            <a @click="deleteOffice(props.row)">
                                <b-icon type="is-danger" icon="delete" />
                            </a>
                        </b-tooltip>
                        <b-tooltip
                            type="is-light"
                            :label="$t('usergroup.activateBookingCalendar')"
                        >
                            <b-switch
                                size="is-small"
                                :value="true"
                                type="is-info"
                            />
                        </b-tooltip>

                        <b-tooltip
                            class="is-hidden-mobile"
                            :label="$t('users.addNewUser')"
                            type="is-light"
                        >
                            <a @click="setUsergroup(props.row)">
                                <b-icon icon="plus" type="is-success" />
                            </a>
                        </b-tooltip>
                    </b-table-column>

                    <template slot="detail" slot-scope="props">
                        <nav class="level">
                            <div class="level-left">
                                <div class="level-item">
                                    <p>
                                        <strong>
                                            {{ $t('general.photographers') }}
                                        </strong>
                                        <br />
                                        <span class="is-size-7">
                                            <b-field grouped group-multiline>
                                                <div
                                                    v-for="(user, i) in props
                                                        .row.sharedWith"
                                                    :key="i"
                                                    class="control"
                                                >
                                                    <b-tooltip
                                                        type="is-danger"
                                                        :label="
                                                            $t(
                                                                'usergroup.stopSharingOffice'
                                                            )
                                                        "
                                                    >
                                                        <b-tag
                                                            type="is-info"
                                                            attached
                                                            aria-close-label="close tag"
                                                            closable
                                                            @close="
                                                                removeSharedUser(
                                                                    user.userAccount,
                                                                    user['@id']
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                user.userAccount
                                                                    .fullName
                                                            }}
                                                        </b-tag>
                                                    </b-tooltip>
                                                </div>
                                            </b-field>
                                        </span>
                                    </p>
                                </div>
                            </div>

                            <div class="level-right">
                                <div class="level-item">
                                    <div class="buttons">
                                        <b-button
                                            type="is-info"
                                            icon-left="share-variant"
                                            size="is-small"
                                            rounded
                                            outlined
                                        >
                                            {{ $t('usergroup.shareOffice') }}
                                        </b-button>

                                        <router-link
                                            :to="{
                                                name: 'EditUsergroup',
                                                params: {
                                                    uuid: props.row.uuid,
                                                    ug: props.row
                                                }
                                            }"
                                        >
                                            <b-button
                                                type="is-info"
                                                icon-left="square-edit-outline"
                                                size="is-small"
                                                rounded
                                                outlined
                                            >
                                                {{ $t('general.editDetails') }}
                                            </b-button>
                                        </router-link>
                                        <br />
                                        <b-button
                                            v-if="
                                                props.row.members.length === 0
                                            "
                                            class="is-hidden-tablet"
                                            rounded
                                            outlined
                                            size="is-small"
                                            icon-left="delete"
                                            type="is-danger"
                                            @click="deleteOffice(props.row)"
                                        >
                                            {{ $t('users.deleteOffice') }}
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </nav>

                        <hr />
                        <b-table
                            :data="props.row.members"
                            narrowed
                            striped
                            sortable
                            hoverable
                            sort-icon="chevron-up"
                        >
                            <b-table-column
                                v-slot="subprops"
                                field="fullName"
                                :label="$t('users.fullName')"
                                sortable
                            >
                                {{ subprops.row.fullName }}
                            </b-table-column>

                            <b-table-column
                                v-slot="subprops"
                                field="email"
                                :label="$t('general.email')"
                                sortable
                            >
                                {{ subprops.row.email }}
                            </b-table-column>

                            <b-table-column
                                v-slot="subprops"
                                field="customerRole"
                                :label="$t('users.userRole')"
                                sortable
                            >
                                {{ customerRole(subprops.row.customerRole) }}
                            </b-table-column>

                            <b-table-column
                                v-slot="subprops"
                                field="lastLogin"
                                :label="$t('general.lastLogin')"
                                centered
                                sortable
                            >
                                <span>
                                    {{
                                        localeDateTimeString(
                                            subprops.row.lastLogin
                                        )
                                    }}
                                </span>
                            </b-table-column>

                            <b-table-column v-slot="subprops" numeric>
                                <b-tooltip
                                    type="is-light"
                                    :label="$t('usergroup.editUser')"
                                >
                                    <a @click="editUser(subprops.row)">
                                        <b-icon icon="account-edit-outline" />
                                    </a>
                                </b-tooltip>
                                <b-tooltip
                                    type="is-danger"
                                    :label="$t('usergroup.deleteUser')"
                                >
                                    <a @click="deleteUser(subprops.row)">
                                        <b-icon
                                            icon="account-cancel-outline"
                                            type="is-danger"
                                        />
                                    </a>
                                </b-tooltip>
                            </b-table-column>

                            <template slot="empty">
                                <section class="section">
                                    <div
                                        class="content has-text-grey has-text-centered"
                                    >
                                        <p>
                                            <b-icon
                                                icon="emoticon-sad"
                                                size="is-large"
                                            >
                                            </b-icon>
                                        </p>
                                        <p>{{ $t('users.noUsersInGroup') }}</p>
                                    </div>
                                </section>
                            </template>
                        </b-table>
                    </template>
                </b-table>
            </div>
        </div>
        <b-modal
            v-model="editUserMode"
            trap-focus
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-modal
        >
            <template #default="props">
                <EditUserModal :user="editCustomer" @close="props.close" />
            </template>
        </b-modal>
    </section>
</template>

<script>
import ErrorMessage from '../../components/ErrorMessage'
import EditUsergroup from '../../components/usergroup/Edit'
import NewCustomer from '../../components/user/New'
import EditUserModal from '../../components/user/SimpleEdit'

export default {
    name: 'NewCustomer',
    // eslint-disable-next-line vue/no-unused-components
    components: { ErrorMessage, EditUsergroup, NewCustomer, EditUserModal },
    data() {
        return {
            search: '',
            show: true,
            newCustomer: false,
            editUserMode: false,
            editUsergroup: false,
            data: [],
            officeName: '',
            customer: {
                officeIri: null,
                type: 'broker',
                email: null,
                username: '',
                firstName: '',
                lastName: '',
                phone: null
            },
            editCustomer: {
                iri: null,
                type: null,
                email: null,
                firstName: null,
                lastName: null,
                phone: null
            },
            columns: [
                {
                    field: 'fullName',
                    label: 'Full name'
                },
                {
                    field: 'email',
                    label: 'E-post'
                },
                {
                    field: 'userRole',
                    label: 'Role'
                },
                {
                    field: 'lastLogin',
                    label: 'Last login'
                }
            ],
            defaultOpenedDetails: [1],
            showDetailIcon: true
        }
    },
    computed: {
        isLoadingUsergroup() {
            return this.$store.getters['usergroup/isLoading']
        },
        hasErrorCustomer() {
            return this.$store.getters['customer/hasError']
        },
        errorCustomer() {
            return this.$store.getters['customer/error']
        },
        hasUsergroups() {
            return this.$store.getters['usergroup/hasUsergroups']
        },
        customerGroups() {
            return this.$store.getters['usergroup/usergroups']
        },
        getResponseStatus() {
            return this.$store.getters['usergroup/status']
        },
        getUserResponseStatus() {
            return this.$store.getters['customer/status']
        },
        getAccessResponseStatus() {
            return this.$store.getters['usergroupAccess/status']
        },
        filteredOfficeArray() {
            return this.customerGroups.filter(option => {
                return (
                    option.name
                        .toString()
                        .toLowerCase()
                        .indexOf(this.officeName.toLowerCase()) >= 0
                )
            })
        }
    },
    methods: {
        toggle(row) {
            this.$refs.table.toggleDetails(row)
        },
        localeDateTimeString(datetime) {
            let date = new Date(datetime)
            return datetime
                ? date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
                : '-'
        },
        searchUsersAndGroups() {
            console.log('you shall search!')
        },
        customerRole(role) {
            if (role === 'ROLE_CUSTOMER') {
                return this.$t('users.broker')
            } else if (role === 'ROLE_GROUP_ADMIN') {
                return this.$t('users.groupAdmin')
            } else {
                return this.$t('general.error')
            }
        },
        proposedUsername() {
            let fullName =
                this.customer.username !== '.'
                    ? this.customer.firstName + '.' + this.customer.lastName
                    : ''

            this.customer.username = fullName
                .toLowerCase()
                .replace(/[åÅäÄ]/g, 'a')
                .replace(/[öÖ]/g, 'o')
                .replace(/[^a-zA-Z0-9.]+/g, '-')
        },
        setUsergroup(office) {
            this.newCustomer = true
            this.customer.officeIri = office['@id']
            this.officeName = office.name
        },
        showAddNewOffice() {
            this.$buefy.dialog.prompt({
                message: this.$t('users.dialog.addNewOffice.label'),
                inputAttrs: {
                    placeholder: this.$t(
                        'users.dialog.addNewOffice.placeholder'
                    ),
                    maxlength: 100,
                    value: this.officeName
                },
                confirmText: this.$t('users.buttons.add'),
                cancelText: this.$t('users.buttons.cancel'),
                onConfirm: async value => {
                    let newUsergroup = await this.$store.dispatch(
                        'usergroup/create',
                        { name: value, logo: null }
                    )
                    this.$refs.autocomplete.setSelected(newUsergroup)
                }
            })
        },
        async addNewUser() {
            await this.$store.dispatch('customer/create', this.customer)
            if (this.getUserResponseStatus === 201) {
                this.$store.dispatch('usergroup/listAll')
                this.$buefy.snackbar.open({
                    message: this.$t('users.create.success'),
                    indefinite: true,
                    queue: false
                })
                this.newCustomer = false
                this.customer = { ...this.resetForm }
                this.officeName = ''
            }
        },
        deleteUser(user) {
            this.$buefy.dialog.confirm({
                title: this.$t('dialogs.delete.title'),
                message: this.$t('dialogs.delete.message', {
                    object: user.fullName
                }),
                confirmText: this.$t('dialogs.delete.confirmButton'),
                cancelText: this.$t('dialogs.delete.cancelButton'),
                type: 'is-danger',
                hasIcon: true,
                onConfirm: async () => {
                    await this.$store.dispatch('customer/delete', user['@id'])
                    if (this.getUserResponseStatus === 204) {
                        this.$store.dispatch('usergroup/listAll')
                        this.$buefy.snackbar.open({
                            message: this.$t('users.delete.success'),
                            indefinite: true,
                            queue: false
                        })
                    }
                }
            })
        },
        editUser(user) {
            let type =
                user.customerRole === 'ROLE_GROUP_ADMIN'
                    ? 'customerAdmin'
                    : 'broker'
            this.editCustomer = {
                iri: user['@id'],
                type: type,
                email: user.email,
                firstName: user.firstName ?? '',
                lastName: user.lastName ?? '',
                phone: user.phone ?? ''
            }
            this.editUserMode = true
        },
        deleteOffice(office) {
            this.$buefy.dialog.confirm({
                title: this.$t('dialogs.delete.title'),
                message: this.$t('dialogs.delete.message', {
                    object: office.name
                }),
                confirmText: this.$t('dialogs.delete.confirmButton'),
                cancelText: this.$t('dialogs.delete.cancelButton'),
                type: 'is-danger',
                hasIcon: true,
                onConfirm: async () => {
                    await this.$store.dispatch(
                        'usergroup/delete',
                        office['@id']
                    )
                    if (this.getResponseStatus === 204) {
                        this.$store.dispatch('usergroup/listAll')
                    }
                }
            })
        },
        removeSharedUser(user, access) {
            this.$buefy.dialog.confirm({
                title: this.$t('users.dialog.removeSharedUser.title'),
                message: this.$t('users.dialog.removeSharedUser.message', {
                    object: user.fullName
                }),
                confirmText: this.$t('dialogs.delete.confirmButton'),
                cancelText: this.$t('dialogs.delete.cancelButton'),
                type: 'is-warning',
                hasIcon: true,
                onConfirm: async () => {
                    await this.$store.dispatch('usergroupAccess/delete', access)
                    if (this.getAccessResponseStatus === 204) {
                        this.$store.dispatch('usergroup/listAll')
                    }
                }
            })
        }
    },
    created() {
        this.$store.dispatch('usergroup/listAll')
        this.resetForm = { ...this.customer }
    }
}
</script>

<style scoped>
.is-four-fifths {
    margin: 0 auto;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
