<template>
    <form>
        <div class="box">
            <b-field grouped group-multiline>
                <b-field :label="$t('users.firstName')" expanded>
                    <b-input v-model.trim="customer.firstName" required />
                </b-field>

                <b-field :label="$t('users.lastName')" expanded>
                    <b-input v-model.trim="customer.lastName" required />
                </b-field>

                <b-field :label="$t('general.phone')" expanded>
                    <b-input v-model.trim="customer.phone" type="tel" />
                </b-field>
            </b-field>

            <b-field :label="$t('general.email')" expanded>
                <b-input v-model.trim="customer.email" type="email" required />
            </b-field>
            <b-field grouped>
                <b-field
                    :label="$t('users.userRole')"
                    :message="$t('users.roleExplanation')"
                >
                    <div class="block">
                        <b-radio
                            v-model="customer.type"
                            name="type"
                            native-value="broker"
                        >
                            {{ $t('users.broker') }}
                        </b-radio>

                        <b-radio
                            v-model="customer.type"
                            name="type"
                            native-value="customerAdmin"
                        >
                            {{ $t('users.groupAdmin') }}
                        </b-radio>
                    </div>
                </b-field>
                <b-field>
                    <div class="buttons is-pulled-right">
                        <b-button @click="$emit('close')">
                            Cancel
                        </b-button>
                        <b-button
                            type="is-success"
                            :disabled="requiredForUpdateUser"
                            @click="updateUser"
                        >
                            Update
                        </b-button>
                    </div>
                </b-field>
            </b-field>
        </div>
    </form>
</template>

<script>
export default {
    name: 'SimpleEdit',
    props: {
        user: Object
    },
    data() {
        return {
            customer: {
                iri: '',
                type: '',
                email: '',
                firstName: '',
                lastName: '',
                phone: ''
            }
        }
    },
    computed: {
        requiredForUpdateUser() {
            return (
                !this.customer.email ||
                !this.customer.firstName ||
                !this.customer.lastName ||
                !this.customer.type ||
                !this.customer.iri
            )
        }
    },
    methods: {
        async updateUser() {
            let response = await this.$store.dispatch(
                'customer/update',
                this.customer
            )
            if (response.status === 204) {
                this.$buefy.snackbar.open({
                    message: this.$t('usergroup.editUserSuccess'),
                    type: 'is-success',
                    position: 'is-bottom-right',
                    actionText: 'OK',
                    queue: false
                })
                this.$emit('close')
            }
        }
    },
    created() {
        this.customer = this.user
    }
}
</script>
